export class IfContent {
  iframeagentid: any;
  ifenvid:any;
  ifbotcolor: any;
  ifcustcolor: any;
  ifcustname: any;
  ifbotname: any;
  ifbotrole: any;
  ifboticon: any;
  ifbotmsgicon: any;
  ifcsmsgicon:any;
  iflangoption: any;
  iflanguagesrequired: any;
  ifselectedchipcolor: any;
  ifbotfontcolor: any;
  ifcustomerfontcolor: any;
  ifsendbtncolor:any;
  ifchipcolor:any
  constructor(
    iframeagentid: any,
    ifenvid:any,
    ifbotcolor: any,
    ifcustcolor: any,
    ifcustname: any,
    ifbotname: any,
    ifbotrole: any,
    ifboticon: any,
    ifbotmsgicon: any,
    ifcsmsgicon:any,
    iflangoption: any,
    ifbotfontcolor:any,
    ifcustomerfontcolor:any,
    ifselectedchipcolor:any,
    iflanguagesrequired:any,
    ifsendbtncolor:any,
    ifchipcolor:any
  ) {
      this.iframeagentid = iframeagentid;
      this.ifenvid       = ifenvid;
      this.ifbotcolor    = ifbotcolor;
      this.ifcustcolor   = ifcustcolor;
      this.ifcustname    = ifcustname;
      this.ifbotname     = ifbotname;
      this.ifbotrole     = ifbotrole;
      this.ifboticon     = ifboticon;
      this.ifbotmsgicon  = ifbotmsgicon;
      this.ifcsmsgicon   = ifcsmsgicon;
      this.iflangoption  = iflangoption;
      this.ifbotfontcolor = ifbotfontcolor;
      this.ifcustomerfontcolor = ifcustomerfontcolor;
      this.ifselectedchipcolor = ifselectedchipcolor;
      this.iflanguagesrequired = iflanguagesrequired;
      this.ifsendbtncolor = ifsendbtncolor;
      this.ifchipcolor = ifchipcolor;
  }
}
