import { Injectable, Input } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
import { exhaustMap, map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { newMessage } from '../../app/chatbot/newMessage';
import { Message } from './message';
// import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private sharedData = new BehaviorSubject<any>(null);
  private sharedChatbotData = new BehaviorSubject<any>(null);
  private newMessages!: newMessage;

  public oldMesg: Message = {
     avatar:'',
     content:[],
     langstatus:false,
     richcontent:[],
     type:''
  };

  public newMsg: newMessage = {
    text: [],
    option:[],
    flag: 0,
    isUser: false,
    textLength:3
  }
  
  constructor(private http: HttpClient) { }
  
  async sentAgentMessage(data: any) {
    let payload = {
      ...data ,
      'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
      //  return this.http
      //      .post<any>('https://2072-103-156-209-173.ngrok-free.app/voice/webhooks/chatbot',payload,
      //      { observe: 'response' })
      //      .pipe(
      //       map( (responseData)=>{
      //         console.log("responseData ",responseData)
      //         let mes = this.processResponse(responseData?.body?.payload)
      //         responseData.body['message'] = mes;
      //         return responseData
      //        })
      //        );
      const responseData = await this.http
      .post<any>('https://test.oxylym.com/voice/webhooks/chatbot', payload, { observe: 'response' })
      .toPromise();
      let mes = await this.processResponse(responseData?.body?.payload);
      responseData.body['message'] = mes;
      console.log("responseData ", responseData);
      return responseData;      
  }

  processResponse(response:any):any {
    console.log("inside processResponse response = ", response);
    this.oldMesg.content = [];
    this.oldMesg.richcontent = [];
    response.forEach((item:any, index:number) => {
      //console.log("response.foreach n item =",item);
        if (item.fullFillmentText) {
          //console.log("in FT of processResponse",item);
            this.displayText(item.fullFillmentText, index);
        } else if (item.richContent) {
          //console.log("in RC of processResponse item",item);

          let data:any[] = [];         
          if(item.richContent.length === 1){
            // console.log("item.richContent[0][0].chip_type");
            if(item.richContent[0]?.length){
              // console.log("heree");              
              if(item.richContent[0][0]){
                // console.log("insideee");                
                if(item.richContent[0][0].chip_type === "job_listing"){
                  data = [{chip_type: "job_listing"}]
                  // console.log("data in job listing",data);
                }  else {
                  // console.log("..weweqww....")
                  data = item.richContent[0];
                }
              }
              else {
                // console.log("......")
                data = item.richContent[0];
              }
              
            }else{
              console.log("...sdjfkds...")
              data = [item.richContent[0]];
            }
            console.log("dataa ",data);
           }else if(item.richContent[0]?.[0]?.chip_type === "horizontal"){
            data = [{chip_type: "horizontal"}]
            // console.log("data",data);
          }          
          else if(item.richContent[0]?.[0]?.chip_type === "job_listing"){
            data = [{chip_type: "job_listing"}]
            //console.log("data in job listing",data);
          }
           else{
            // console.log("..==1....")
            item.richContent.forEach((i:any) => {
              i.forEach((e:any) => {
                data.push(e);
              })
            });
            
          }
         
          console.log("data",data);      
          // console.log("findA",findA);  
            data.forEach((j:any, index:number) => {
              // console.log("J",j)
                switch (j.type) {
                    case "chips":
                      this.displayRichcontent(j, "chips", 0);
                      break;
                    
                    case "info":
                      console.log("infoooo***",j);
                      if(j.options){
                        this.displayRichcontent(j.options, "info", 3);
                      }
                      else if(j.faq_flag){
                        this.displayRichcontent([j],"infotext", 0)
                      }
                      else if(j.actionLink){
                        this.displayRichcontent([j],"scheduleMeeting", 0)
                      }
                      else if(j.subtitle){
                        this.displayRichcontent([j],"infotext", 3)
                      }else{
                        this.displayText(j.title,index);
                      }
                        break;
                    
                    case "email":
                      this.displayRichcontent(item.richContent, "email", 3);
                      break;

                    case "list":
                      this.displayRichcontent(j, "list",0);
                      break;

                    case "accordion":
                      // console.log("accrdian",j);
                      this.displayRichcontent(j,"accordion",0);
                      break;
            
                    default:
                      break;
                }

                switch (j.chip_type) {                  
                    case "horizontal":
                      console.log("item.richContent",item.richContent)
                      this.displayRichcontent(item.richContent,"horizontal",3)
                      break;

                    case "form":
                      this.displayRichcontent([], "form", 3);
                      break;

                    case "job_listing":
                      console.log("item.richContent in joblisting",item.richContent)
                      this.displayRichcontent(item.richContent,"jobListing",3)
                      break;
  
                    default:
                    break;
                }     
              }
            );
        }
    });
    console.log(this.oldMesg, "return value")
    return this.oldMesg
  }

  displayText = (text: any, index:number) => {
    // Implement the logic to display the text
    console.log("Text displayText:", text, index);
      const displayTextValues =[ {
          text:text ,
          flag: index === 0,
          isUser: false,
          option: [],
          textLength: 3
        }]

      console.log("displayTextValues",displayTextValues);
       
       displayTextValues.map((item:any) => {
        console.log("item",item);
        if(item?.text.length > 0 ){
          console.log(item.text);
          //let temp = item.text
          this.oldMesg.content?.push(item.text);
        }
        console.log("this.oldMesg = ",this.oldMesg);
        
       })
        
  }

  displayRichcontent = (chips:any, type:any, index:number) => {
    console.log("chips **********",chips);
    const displayRCValues = [{
        text: '',
        type:type,
        flag: index === 0,
        isUser: false,
        option: chips,
        textLength: 3,
        uid:Math.random()
      }];

      displayRCValues.map((val:any) => {
        if(val.option){
          this.oldMesg.richcontent?.push([val.option])
        }
        console.log("this.oldMesg in RC()",this.oldMesg);
      })
  }
  // sentAgentMessage(data: any) {
  //   let payload = {
  //     ...data ,
  //     'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
  //   }
  //      return this.http
  //          .post<any>('https://test.oxylym.com/voice/webhooks/chatbot',payload,
  //          { observe: 'response' })
  //          .pipe(
  //           map((responseData)=>{
  //             return responseData;
  //            })
  //            );
  // }

  setData(data: any) {
    this.sharedData.next(data);
  }

  getData() {
    return this.sharedData.asObservable();
  }

  setChatbotData(data: any) {
    // console.log("data in service",data);
    this.sharedChatbotData.next(data);
  }

  getChatbotData() {
    return this.sharedChatbotData.asObservable();
  }

}


