<li class="list-group-item">
  <div *ngIf="message.type == 'bot' && deviceType != 'xs'" class="bot-message-item" >
    <div
      *ngIf="message.content?.length != 0 || message.richcontent != null"
      mat-card-avatar
      class="bot-header-item"
      [ngStyle]="{ 'background-image': 'url(' + ifcontent.ifbotmsgicon + ')' }">
    </div>
    <div style="width: 200px" *ngIf="message.content?.length == 0 || !message.content">
      <app-loader></app-loader>
    </div>
    <div>
      <ng-container *ngFor="let option of message.richcontent">
        <ng-container *ngFor="let inneroption of option">
          <div style="margin-bottom: 1ch" *ngIf="inneroption.type == 'accordion'">            
            <cdk-accordion class="example-accordion">
              <cdk-accordion-item
                #accordionItem="cdkAccordionItem"
                class="example-accordion-item"
                role="button"
                tabindex="0"
                [attr.aria-expanded]="accordionItem.expanded" >
                <mat-card>
                  <div class="example-accordion-item-header" (click)="accordionItem.toggle()" >
                    <p><b>Transactions related to your query : </b></p>
                    <p>
                      <span (click)="onclikAccordian(inneroption.text)" class="example-accordion-item-description" >
                        Click to {{ accordionItem.expanded ? "close" : "open" }}
                      </span>
                    </p>
                  </div>

                  <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" >
                    <p>
                      <b>{{ inneroption.title }}</b>
                    </p>
                    <p></p>
                    <p *ngFor="let text of accordianText">
                      <b>{{ text }}</b>
                    </p>
                  </div>
                </mat-card>
              </cdk-accordion-item>
            </cdk-accordion>            
          </div>
          
          <mat-card style="margin-bottom: 1ch" *ngIf="inneroption.type === 'list'">
            <p><b>{{ inneroption.title }}</b></p>
            <p class="responsetext-item">{{ inneroption.subtitle }}</p>
          </mat-card>

          <br *ngIf="inneroption.type === 'divider'" />

          <ng-container *ngFor="let i of inneroption">
            <div style="margin-bottom: 1ch" *ngIf="i.type === 'info'">
              <mat-card class="infoCard">
                <img
                  *ngIf="i?.image?.src?.rawUrl"
                  mat-card-sm-image
                  [src]="i.image.src.rawUrl"
                />
                <p>
                  <b>{{ i.title }}</b>
                </p>
                <p></p>
                <p class="responsetext-item">{{ i.subtitle }}</p>
              </mat-card>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <mat-card *ngIf="message.content?.length != 0" class="bot-item-matcard p-left"
        [ngStyle]="{ 'background-color': botcolor, color: botfontcolor }" style="--h: 8px" >
        <div *ngFor="let messages of message.content" class="message">
          {{ messages }}
        </div>
      </mat-card>
      <ng-container *ngFor="let option of message.richcontent">
        <ng-container *ngFor="let inneroption of option">
          <div *ngIf="inneroption.chip_type === 'horizontal'">
            <mat-chip-list *ngIf="inneroption.type == 'chips'">
              <div style="margin-left: 2ch">
                <div class="wrapper">
                  <div class="card-outer-container">
                    <div class="card-inner-container">
                      <mat-chip
                        *ngFor="let chipset of inneroption.options; let i = index"
                        [ngStyle]="{'pointer-events': chipsetSelect ? 'none' : 'auto'}"
                        class="horizontal-chipset-item chipset-item"
                        style="background-color: #f9e1e1; color: black"
                        [class.active]="i === activeIndex"
                        (click)="onChipsetClick(i, chipset.text, message.langstatus)"
                        [selected]="chipset"
                        [value]="chipset" >
                        <img *ngIf="chipset?.image?.src?.rawUrl" matChipAvatar [src]="chipset.image.src.rawUrl" />
                        {{ chipset.text }}
                      </mat-chip>
                    </div>
                  </div>
                </div>
              </div>
            </mat-chip-list>
          </div>
          <ng-container *ngIf="inneroption.chip_type !== 'horizontal'">
            <mat-chip-list *ngIf="inneroption.type == 'chips'">
              <div style="margin-left: 2ch">
                <mat-chip [ngStyle]="{'pointer-events': chipsetSelect ? 'none' : 'auto'}"
                  class="chipset-item"
                  style="background-color: #f9e1e1; color: black"
                  *ngFor="let chipset of inneroption.options; let i = index"
                  [class.active]="i === activeIndex"
                  (click)="onChipsetClick(i, chipset.text, message.langstatus)"
                  [selected]="chipset"
                  [value]="chipset" >
                  <img *ngIf="chipset?.image?.src?.rawUrl" matChipAvatar [src]="chipset.image.src.rawUrl"/>
                  {{ chipset.text }}
                </mat-chip>
              </div>
            </mat-chip-list>
          </ng-container>
        </ng-container>
      </ng-container>
      <br />
    </div>
  </div>

  <div *ngIf="message.type == 'bot' && deviceType == 'xs'" class="bot-message-item-xs" >
    <div
      *ngIf="message.content?.length != 0 || message.richcontent != null"
      mat-card-avatar
      [ngStyle]="{ 'background-image': 'url(' + ifcontent.ifbotmsgicon + ')' }"
      class="bot-header-item-xs">
    </div>
    <div style="width: 300px">
      <div style="width: 200px" *ngIf="message.content?.length == 0 || !message.content">
        <app-loader></app-loader>
      </div>

      <ng-container *ngFor="let option of message.richcontent">
        <ng-container *ngFor="let inneroption of option">          
          <div style="margin-bottom: 1ch" *ngIf="inneroption.type == 'accordion'">
            <!-- <mat-card> -->
            <cdk-accordion class="example-accordion">
              <cdk-accordion-item
                #accordionItem="cdkAccordionItem"
                class="example-accordion-item"
                role="button"
                tabindex="0"
                [attr.aria-expanded]="accordionItem.expanded" >
                <mat-card>
                  <div class="example-accordion-item-header" (click)="accordionItem.toggle()" >
                    <p><b>Transactions related to your query</b></p>
                    <p>
                      <span (click)="onclikAccordian(inneroption.text)" class="example-accordion-item-description" >
                        Click to {{ accordionItem.expanded ? "close" : "open" }}
                      </span>
                    </p>
                  </div>

                  <div
                    class="example-accordion-item-body"
                    role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'"
                  >
                    <p>
                      <b>{{ inneroption.title }}</b>
                    </p>
                    <p></p>
                    <p *ngFor="let text of accordianText">
                      <b>{{ text }}</b>
                    </p>
                  </div>
                </mat-card>
              </cdk-accordion-item>
            </cdk-accordion>
            <!-- </mat-card> -->
          </div>
         
          <mat-card *ngIf="inneroption.type === 'list'">hiii
            <p>
              <b>{{ inneroption.title }}</b>
            </p>
            <p class="responsetext-item">{{ inneroption.subtitle }}</p>
          </mat-card>
          <br *ngIf="inneroption.type == 'divider'" />

           <ng-container *ngFor="let i of inneroption">
          <div style="margin-bottom: 1ch" *ngIf="i.type == 'info'">
            <mat-card>
              <img
                *ngIf="i?.image?.src?.rawUrl"
                mat-card-image
                [src]="i.image.src.rawUrl"
              />
              <p>
                <b>{{ i.title }}</b>
              </p>
              <p></p>
              <p class="responsetext-item">{{ i.subtitle }}</p>
            </mat-card>
          </div>
          </ng-container>
        
      </ng-container>
      </ng-container>
      <mat-card
        *ngIf="message.content?.length != 0 "
        class="bot-item-matcard-xs p-left"
        [ngStyle]="{ 'background-color': botcolor }"
        style="--h: 8px"
      >
        <div *ngFor="let messages of message.content" class="message-xs">
          {{ messages }}
        </div>
      </mat-card>
      <ng-container *ngFor="let option of message.richcontent">
        <ng-container *ngFor="let inneroption of option">
          <mat-chip-list *ngIf="inneroption.type == 'chips'">
            <div style="margin-left: 1ch">
              <mat-chip
                class="chipset-item"
                *ngFor="let chipset of inneroption.options; let i = index"
                [selected]="chipset"
                [value]="chipset"
                [class.active]="i === activeIndex"
                (click)="onChipsetClick(i, chipset.text, message.langstatus)"
              >
                {{ chipset.text }}
              </mat-chip>
            </div>
          </mat-chip-list>
        </ng-container>
      </ng-container>
      <br />
    </div>
  </div>

  <div style="clear: both"></div>

  <div *ngIf="message.type == 'user' && deviceType != 'xs'" class="user-message-item" >
    <mat-card
      *ngIf="message.content"
      class="user-item-matcard p-right"
      [ngStyle]="{ 'background-color': custcolor, color: customerfontcolor }" >
      <div class="user-message">
        <p>{{ message.content }}</p>
      </div>
    </mat-card>
    <!-- <div  mat-card-avatar class="user-header-item" [ngStyle]="{'background-image': 'url(' + ifcontent.ifcsmsgicon + ')'}"></div> -->
  </div>

  <div *ngIf="message.type == 'user' && deviceType == 'xs'" class="user-message-item-xs" >
    <div
      style="float: right; margin-left: 0.5ch"
      mat-card-avatar
      [ngStyle]="{ 'background-image': 'url(' + ifcontent.ifcsmsgicon + ')' }"
      class="user-header-item">
    </div>
    <mat-card
      *ngIf="message.content"
      class="user-item-matcard-xs p-right"
      [ngStyle]="{ 'background-color': custcolor, color: customerfontcolor }" >
      <div class="user-message-xs">
        <p>{{ message.content }}</p>
      </div>
    </mat-card>
  </div>

  <div style="clear: both"></div>
</li>

