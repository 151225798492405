export class Message {
    type?:string
    content: string[];  //fulfillmentText
   // voicetext:string;
   // richtype?:string;
    richcontent?:any[]; //richcontent
    avatar: string;
    langstatus:boolean;

    constructor(type:string,content:string[],chipset:any,avatar: string,langstatus:boolean){
      this.type = type;
      this.content = content;
     // this.richtype = dstype;
      this.richcontent = chipset;
      this.avatar = avatar;
      this.langstatus = langstatus;
    }
   
  }
  