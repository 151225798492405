    <div *ngIf="deviceType != 'xs' && chatClose == true" class="example-card">
      <mat-card class="chat-header">
        <mat-card-header>
          <div
            mat-card-avatar
            class="example-header-image"
            [ngStyle]="{
              'background-image': 'url(' + iframeContent.ifboticon + ')'
            }"
          ></div>
          <mat-card-title>
            <h4 style="margin-top: 1ch" mat-dialog-title>
              <!-- <mat-toolbar  class="task-header">  -->
              <span class="header-title" [ngStyle]="{ color: ifselectedchipcolor }">{{ ifbotname }} </span>({{ ifbotrole }})
              <p>
                by
                <span class="header-title" [ngStyle]="{ color: ifselectedchipcolor }">{{ifcustname}}</span>
              </p>
              <!-- <span *ngIf="agentdetails != null" style="margin-top: 4ch;" class="header-title">{{agentdetails.agentname}}</span> -->
              <!-- </mat-toolbar>  -->
              <!-- <p style="float:right;margin-left:2px;"></p> -->
            </h4></mat-card-title
          >
          <span class="fx-spacer"></span>
          <!-- <span class="header-title">Language</span> -->
          <span
            style="margin-top: 1.4ch"
            class="header-title"
            [ngStyle]="{ color: ifbotcolor }"
            >{{ languageSelected }}</span
          >
          <!-- <mat-icon style="cursor:pointer;color:
                #ff6600;margin-top: 0.35ch;"  *ngIf="voiceAssistState == true"(click)="onVoiceassistClick()">volume_down</mat-icon> -->
          <mat-icon
            style="float: right; cursor: pointer; margin-top: 0.35ch"
            [ngStyle]="{ color: ifselectedchipcolor }"
            *ngIf="voiceAssistState == true"
            (click)="onVoiceassistClick()"
            >volume_down</mat-icon
          >
          <mat-icon
            style="float: right; cursor: pointer; margin-top: 0.35ch"
            [ngStyle]="{ color: ifselectedchipcolor }"
            *ngIf="voiceAssistState == false"
            (click)="onVoiceassistClick()"
            >volume_off</mat-icon
          >
          <mat-icon
            style="margin-top: 0.35ch; color: black; cursor: pointer"
            (click)="onCloseClick()">close</mat-icon>
        </mat-card-header> 
      </mat-card>
      
      <app-message-list  [ifcontent] = "iframeContent" [voiceAssistState]="voiceAssistState" [iframeagentid]="iframeagentid" [botcolor] = "ifbotcolor" [custcolor]="ifcustcolor" [deviceType]="deviceType" (chiplang) = "setCurrentLang($event)" [langSelected] ="selectedlang"  [messages]="messages" [sessionId]="sessionId" [customerfontcolor]="ifcustomerfontcolor" [botfontcolor]="ifbotfontcolor" [chipcolor]="ifchipcolor" [selectedchipcolor]="ifselectedchipcolor"></app-message-list>
      <app-message-form  [ifcontent] = "iframeContent" [voiceAssistState]="voiceAssistState" [iframeagentid]="iframeagentid" [botcolor] = "ifbotcolor" [custcolor]="ifcustcolor" [deviceType]="deviceType" [langSelect] = "selectedlang" [message]="message" (recStarted) = "recodringStarted($event)" [messages]="messages"  [sessionId]="sessionId" [sendbtncolor]="ifsendbtncolor"></app-message-form>
    </div>

    <div *ngIf="deviceType == 'xs' && chatClose == true" class="example-card-xs">
            <mat-card class="chat-header">  
                <mat-card-header> 
                    <div mat-card-avatar class="example-header-image"  [ngStyle]="{'background-image': 'url(' + iframeContent.ifboticon + ')'}"></div> 
                    <p class="header-title-xs" [ngStyle]="{'color': ifbotcolor}">{{ifbotname}}</p>
                    <span style="margin-top: 1.2ch;" class="header-title-xs" [ngStyle]="{'color': ifbotcolor}">{{languageSelected}}</span>
                    <mat-icon style="margin-top: 0.3ch;cursor:pointer" [ngStyle]="{'color': ifbotcolor}" *ngIf="voiceAssistState == true"(click)="onVoiceassistClick()">volume_down</mat-icon>
                    <mat-icon style="margin-top: 0.3ch;float:right;cursor:pointer;" [ngStyle]="{'color': ifbotcolor}" *ngIf="voiceAssistState == false"(click)="onVoiceassistClick()">volume_off</mat-icon>
                    <mat-icon style="color: black;cursor: pointer;" (click)="onCloseClick()">close</mat-icon>
                </mat-card-header> 
            </mat-card>
            <app-message-list  [ifcontent] = "iframeContent" [voiceAssistState]="voiceAssistState" [iframeagentid] = "iframeagentid" [botcolor] = "ifbotcolor" [custcolor]="ifcustcolor" [deviceType]="deviceType" (chiplang) = "setCurrentLang($event)" [langSelected] ="selectedlang"  [messages]="messages" [sessionId]="sessionId" [chipcolor]="ifchipcolor"></app-message-list>
            <app-message-form [ifcontent] = "iframeContent" [voiceAssistState]="voiceAssistState" [iframeagentid] = "iframeagentid" [botcolor] = "ifbotcolor" [custcolor]="ifcustcolor" [deviceType]="deviceType" [langSelect] = "selectedlang" [message]="message" (recStarted) = "recodringStarted($event)" [messages]="messages"  [sessionId]="sessionId" [sendbtncolor]="ifsendbtncolor"></app-message-form>
    </div>
 
  