<div *ngIf="deviceType !='xs'" class="chatlist" #chatlist>
  <ul class="list-group-lg">
    
    <app-message-item [ifcontent]="ifcontent" [iframeagentid] = "iframeagentid" [botcolor] = "botcolor" [custcolor]="custcolor" [voiceAssistState] = "voiceAssistState" [deviceType]="deviceType" [langSelected] ="langSelected" (chiplang) = "setCurrentLang($event)"  [messages] = "messages" *ngFor="let msg of messages" [message]="msg" [sessionId] ="sessionId" [botfontcolor]="botfontcolor" [chipcolor]="chipcolor" [customerfontcolor]="customerfontcolor" [selectedchipcolor]="selectedchipcolor"></app-message-item>

    <!-- <div mat-card-avatar class="oxylium-logo"></div>  -->
  </ul>
</div>


<div *ngIf="deviceType =='xs'" class="chatlist" #chatlist>
  <ul class="list-group">

    <app-message-item [ifcontent]="ifcontent" [iframeagentid] = "iframeagentid" [botcolor] = "botcolor" [custcolor]="custcolor" [voiceAssistState] = "voiceAssistState" [deviceType]="deviceType" [langSelected] ="langSelected" (chiplang) = "setCurrentLang($event)"  [messages] = "messages" *ngFor="let msg of messages" [message]="msg" [sessionId] ="sessionId" [chipcolor]="chipcolor" [customerfontcolor]="customerfontcolor"></app-message-item>

    <!-- <div mat-card-avatar class="oxylium-logo"></div>  -->
  </ul>
</div>