<mat-card class="total-form-mat">
<div class="chatcontrol">
    <div class="form-item">
      <mat-form-field  class="example-full-width" appearance="fill">
        <mat-label [ngClass]="{'custom-style': selectedchip != 'Please type in your input'}" *ngIf="!isUserSpeaking" >{{selectedchip}}</mat-label>
        <mat-label *ngIf="isUserSpeaking">Rec, move mouse🠗 to delete, release to sent</mat-label>
        <input class="input-item"  *ngIf="isUserSpeaking" [readonly]="true"  type="text"  matInput maxlength = "100" autocomplete="off" [(ngModel)]="voicetext"/>

        <ng-container *ngIf="langSelect != 'en'">
          <input class="input-item" (keyup.enter)="sendMessage()"  *ngIf="!isUserSpeaking && deviceType !=  'xs'&& ifcontent.iflangoption == 'true'"  [matKeyboard]="displayKeyboard(langSelect)" matKeyboard.size="50%" type="text" #textinput matInput maxlength = "100" autocomplete="off" [(ngModel)]="message.content"/>
        </ng-container>

        <ng-container *ngIf="langSelect == 'en'">
          <input class="input-item" (keyup.enter)="sendMessage()" *ngIf="!isUserSpeaking && deviceType !=  'xs'&& ifcontent.iflangoption == 'true'" type="text" #textinput matInput maxlength = "100" autocomplete="off" [(ngModel)]="message.content"/>
        </ng-container>

        <input class="input-item" (keyup.enter)="sendMessage()"  *ngIf="!isUserSpeaking && deviceType !=  'xs'&& ifcontent.iflangoption == 'false'"  type="text" #textinput matInput maxlength = "100" autocomplete="off" [(ngModel)]="message.content"/>
        <input class="input-item" (keyup.enter)="sendMessage()"  *ngIf="!isUserSpeaking && deviceType == 'xs'"  type="text" #textinput matInput maxlength = "100" autocomplete="off" [(ngModel)]="message.content"/>
        <!-- <input [matKeyboard]=langSelect matKeyboard.size="50%" type="text" #textinput matInput maxlength = "100" autocomplete="off" [(ngModel)]="voicetext"/> -->
      </mat-form-field>
     <button *ngIf="!isUserSpeaking && deviceType !== 'xs'" (mousedown)="startRecording()" class="btn-record"><mat-icon style=" cursor:pointer;color:black">mic</mat-icon></button>
     <button [ngStyle]="{'background-color': botcolor}" *ngIf="isUserSpeaking && deviceType !== 'xs'" (mouseup)="sendVoiceMessage()" class="btn-record" (mouseleave) ="deleteRecording()"><mat-icon style=" cursor:pointer;color:white">mic</mat-icon></button> 
      
     <!-- for small screen -->
     <button *ngIf="!isUserSpeaking && deviceType == 'xs'" (touchstart)="startRecording()" class="btn-record"><mat-icon style=" cursor:pointer;color:black">mic</mat-icon></button>
     <button [ngStyle]="{'background-color': botcolor}" *ngIf="isUserSpeaking && deviceType == 'xs'" (touchend)="sendVoiceMessage()" class="btn-record" (touchmove) ="deleteRecording()"><mat-icon style=" cursor:pointer;color:white">mic</mat-icon></button>    
     
     <button *ngIf="message.content &&!isUserSpeaking "   class="btn-success"  (click)="sendMessage()"><mat-icon style="color:white">send</mat-icon></button>
     <button [disabled]="true"  *ngIf="!message.content || isUserSpeaking "  class="btn-success"  ><mat-icon style="color:white">send</mat-icon></button>
    </div>     
    
        </div>
  <p *ngIf="deviceType !=  'xs'" class="logo-label"><i>Powered by</i><b [ngStyle]="{'color': botcolor}"> Oxylym</b></p>
  <p *ngIf="deviceType ==  'xs'" class="logo-label-xs"><i>Powered by</i><b [ngStyle]="{'color': botcolor}"> Oxylym</b></p>
  </mat-card>


