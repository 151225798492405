import { Component, Input, OnInit, OnDestroy, Output, HostBinding } from '@angular/core';
import { Message } from 'src/app/chatbot/message';
import { VoicerecognisionService } from '../voicerecognision.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { ChatbotService } from '../chatbot-service';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { IfContent } from '../iframecontent';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss'],
})

export class MessageFormComponent implements OnInit {
  public searchForm!: FormGroup;
  public isUserSpeaking: boolean = false;
  isFetching = false;
  data: any;
  voicetext: string = '';
  @Input('sessionId') sessionId!: any;
  @Input('agentdetails')
  @Input('message') message!: Message;
  @Input('messages') messages!: Message[];
  @Input('iframeagentid') iframeagentid: any;
  @Input('botcolor') botcolor: any;
  @Input('custcolor') custcolor: any;
  @Output() langChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() recStarted: EventEmitter<boolean> = new EventEmitter<boolean>();
  voiceSub!: Subscription;
  @Input('langSelect') langSelect: string = '';
  @Input('deviceType') deviceType: any;
  @Input('voiceAssistState') voiceAssistState!: boolean;
  @Input('ifcontent') ifcontent!: IfContent;
  @Input('sendbtncolor') sendbtncolor:any;
  selectedchip = "Say 'hello/ hi' to start";
  myTimeout: any;
  richContent!: any[];
  accordianText!: string;
  isSub!: Subscription;

  @HostBinding('style.--sendbtncolor')
  get getSendBtnColor(){
    return this.sendbtncolor;
  }
  
  constructor(
    private fb: FormBuilder,
    private voiceRecognition: VoicerecognisionService,
    private chatbotService: ChatbotService
  ) {
    this.searchForm = this.fb.group({
      searchText: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.initVoiceInput();
    this.isSub = this.chatbotService.getChatbotData().subscribe(data => {
    // console.log("data....",data);
    if(data != null){
    this.selectedchip = data;
    }
});
  }
  /**
   * @description Function to stop recording.
   */
  stopRecording() {
    this.voiceRecognition.stop();
    this.isUserSpeaking = false;
  }

  deleteRecording() {
    // console.log("delete recording")
    this.voiceRecognition.stop();
    this.isUserSpeaking = false;
    this.voicetext = '';
  }

  /**
   * @description Function for initializing voice input so user can chat with machine.
   */
  initVoiceInput() {
    // Subscription for initializing and this will call when user stopped speaking.
    this.voiceRecognition.init().subscribe(() => {
      // User has stopped recording
      // Do whatever when mic finished listening
    });
    // Subscription to detect user input from voice to text.
    this.voiceRecognition.speechInput().subscribe((input) => {
      // this.voicetext = input
      //this.messages.push( new Message('user',[input],'','assets/user.jpg'));
    });
  }

  /**
   * @description Function to enable voice input.
   */
  startRecording() {
    // console.log("hereeeevoice")
    this.isUserSpeaking = true;
    this.recStarted.emit(this.isUserSpeaking);
    this.voiceRecognition.start();
    this.voiceSub = this.voiceRecognition.voiceToTextSubject.subscribe(
      (text) => {
        // console.log('Voicetext', text);
        this.voicetext = text;
      }
    );
  }  

  myTimer() {
    window.speechSynthesis.pause();
    window.speechSynthesis.resume();
    this.myTimeout = setTimeout(this.myTimer, 10000);
  }

  public sendVoiceMessage(): void {
// console.log("sendVoiceMessage");
    this.stopRecording();
    if (this.voicetext) {
      this.messages.push(
        new Message('user', [this.voicetext], '', 'assets/user.jpg', false)
      );
      console.log("this.messages in sendvoicemesg = ",this.messages);
      
    }
    this.data = {
      agent_id: this.iframeagentid.toString(),
      session_id: this.sessionId,
      text: this.voicetext,
      // env_id: this.ifcontent.ifenvid.toString(),
      translate_code: this.langSelect,
      session_end: false,
    };

    this.isFetching = true;
    this.chatbotService.sentAgentMessage(this.data).then((responseData) => {
      // console.log("data in 143 message-form");
        if (this.voiceAssistState === true) {
          if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel();
            this.myTimeout = setTimeout(this.myTimer, 10000);
            var toSpeak;
            if (this.langSelect == 'en') {
              toSpeak = responseData.body?.voice_msg?.map((response:any) => response.replace(/<\/?[^>]+(>|$)/g, ''));
              console.log('toSpeak en', toSpeak);
            }
            if (this.langSelect == 'hi') {
              // toSpeak = responseData.body?.fullFillmentText;
              toSpeak = responseData.body?.voice_msg?.map((response:any) => response.replace(/<\/?[^>]+(>|$)/g, ''));
              console.log('toSpeakfullfillment hi',toSpeak);
            }
            var msg = new SpeechSynthesisUtterance(toSpeak);
            msg.lang = this.langSelect;
            //var voices = window.speechSynthesis.getVoices();
            // msg.voice = voices[10];
            msg.volume = 1; // From 0 to 1
            msg.rate = 1; // From 0.1 to 10
            // msg.pitch = 2; // From 0 to 2
            // From 0.1 to 10
            // msg.voice = voices[10];
            if (msg.onend) {
              clearTimeout(this.myTimeout);
            }
            window.speechSynthesis.speak(msg);
          } else {
            // Speech Synthesis Not Supported 😣
            alert("Sorry, your browser doesn't support text to speech!");
          }
        }
        this.messages.push(new Message('bot',responseData.body?.message.content,responseData.body?.message.richcontent,'assets/bot.jpg',false )
        );
        this.isFetching = false;
      });
    this.message = new Message('user', [], '', 'assets/user.jpg', false);
  }

  public sendMessage(): void {
    this.chatbotService.setData('true');
    if (this.message.content.length !== 0) {
      this.messages.push(
        this.message
        // new Message('user',this.message,'','assets/user.jpg')
      );
      console.log("this.messages in sendmesge = ",this.messages);      
    }
    
    this.data = {
      agent_id: this.iframeagentid.toString(),
      session_id: this.sessionId,
      text: this.message.content,
      // env_id: this.ifcontent.ifenvid.toString(),
      translate_code: this.langSelect,
      session_end: false,
    };
   
    this.isFetching = true;
    this.messages.push(new Message('bot',[],'','assets/bot.jpg',false));

    if (this.message.content.length !== 0) {
      this.chatbotService.sentAgentMessage(this.data).then((responseData) => {
          this.chatbotService.setData('false');
          this.messages.pop();
          if (this.voiceAssistState === true) {
            if ('speechSynthesis' in window) {
              window.speechSynthesis.cancel();
              this.myTimeout = setTimeout(this.myTimer, 10000);
              var toSpeak;
              //var msg = new SpeechSynthesisUtterance();
              // msg.text = responseData.body?.fullFillmentText;
              if (this.langSelect == 'en') {
                toSpeak = responseData.body?.voice_msg?.map((response:any) => response.replace(/<\/?[^>]+(>|$)/g, ''));
                console.log('toSpeak english', responseData.body?.voice_msg);
              }
              if (this.langSelect == 'hi') {
                toSpeak = responseData.body?.voice_msg?.map((response:any) => response.replace(/<\/?[^>]+(>|$)/g, ''));
                console.log('toSpeakfullfillment hi',responseData.body?.voice_msg);
              }
              var msg = new SpeechSynthesisUtterance(toSpeak);
              msg.lang = this.langSelect;
              var voices = window.speechSynthesis.getVoices();
              // var voices = window.speechSynthesis.getVoices();
              // msg.voice = voices[10];
              // msg.voice = voices[10];
              msg.volume = 1; // From 0 to 1
              msg.rate = 1; // From 0.1 to 10
              if (msg.onend) {
                clearTimeout(this.myTimeout);
              }
              window.speechSynthesis.speak(msg);
            } else {
              // Speech Synthesis Not Supported 😣
              alert("Sorry, your browser doesn't support text to speech!");
            }
          }

          this.messages.push(
            new Message('bot',responseData.body?.message.content,responseData.body?.message.richcontent,'assets/bot.jpg',false )
          );
          console.log("messages in mesg-form",this.messages);
          this.isFetching = false;
        });
      this.message = new Message('user', [], '', 'assets/user.jpg', false);
    }
  }

  public displayKeyboard(langSelect: any) {
    if (langSelect == 'en') {
      return false;
    } else {
      return langSelect;
    }
    
  }
}
