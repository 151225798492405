import { Component, Input, OnInit,EventEmitter, Output, HostBinding, ElementRef, Renderer2 } from '@angular/core';
import { Message } from  'src/app/chatbot/message';
import { ChatbotService } from '../chatbot-service';
import { IfContent } from '../iframecontent';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {
  @Input('messages') messages!: Message[];
  @Input('message') message!: Message;
  @Input('sessionId')
  sessionId:any;
  @Input('langSelected')
  langSelected: string = 'en'
  data:any;
  isRecording!: boolean;
  @Output() chiplang: EventEmitter<string> = new EventEmitter<string>();
  @Output() voiceassist: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('deviceType') deviceType: any;
  @Input('iframeagentid') iframeagentid:any;
  @Input('botcolor') botcolor:any;
  @Input('custcolor') custcolor:any;
  myTimeout: any;
  @Input('voiceAssistState') voiceAssistState!: boolean;
  @Input('ifcontent')ifcontent!: IfContent;

  @Input('selectedchipcolor') selectedchipcolor:any;
  @Input('customerfontcolor') customerfontcolor:any;

  @Input('chipcolor')chipcolor:any;

  @Input('botfontcolor') botfontcolor:any;

  accordianText!: string[];
  chipsetSelect: boolean = false;
  activeIndex = -1;
  selectedchip='Say "Hello / hi" to start';

  @HostBinding('style.--selectedchipcolor')
  get getMyColor() {
    return this.selectedchipcolor;
  }

  @HostBinding('style.--chipcolor')
  get getChipColor(){
    return this.chipcolor;
  }
  
  constructor(private chatbotService:ChatbotService, private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {}
 
  myTimer(){
    window.speechSynthesis.resume();
    this.myTimeout = setTimeout(this.myTimer, 10000);
  }

  onclikAccordian(accordiantext:string){
     this.accordianText = accordiantext.split('<br><br>');
  }

  onChipsetClickDisabled(chipset:string,langstatus:boolean){
   if(langstatus == true){
    window.alert('Please restart to select a new language');
   }
  }

  onChipsetClick(i:any,selectedChip:any,langstatus:boolean){
    this.chipsetSelect = true;
    this.chatbotService.setData('true');
    this.chatbotService.setChatbotData(selectedChip);
    this.activeIndex = i;

    if(langstatus == true){
      if(selectedChip === 'മലയാളം')
             this.chiplang.emit('ml');

      if(selectedChip === 'हिन्दी')
              this.chiplang.emit('hi');
      
       if(selectedChip === 'ಕನ್ನಡ')
              this.chiplang.emit('kn');

       if(selectedChip === 'English')
              this.chiplang.emit('en');

       if(selectedChip === 'German')
              this.chiplang.emit('de');
    }
    else{
      // console.log("Selected Chips:",selectedChip);
      // console.log("Language Selected:",this.langSelected);
      // this.isLoading = true;
      this.data = {
       agent_id:this.iframeagentid.toString(),
       session_id:this.sessionId,
       text:selectedChip,
      //  env_id:this.ifcontent.ifenvid.toString(),
       translate_code:this.langSelected,
       session_end:false
     }
    //  console.log("translate_code = ",this.langSelected)
     this.messages.push(
      new Message('bot',[],'','assets/bot.jpg',false)
   );
     this.chatbotService.sentAgentMessage(this.data).then(responseData =>{
      console.log("dta in ,essge-item chipclick",responseData );
      this.chatbotService.setData('false');
      this.messages.pop();
      // console.log(" this.messages.pop() ", this.messages);
      if(this.voiceAssistState){
      if ('speechSynthesis' in window) {
        window.speechSynthesis.cancel();
        this.myTimeout = setTimeout(this.myTimer, 10000);

        var toSpeak;
          if(this.langSelected == 'en'){
            toSpeak = responseData.body?.voice_msg?.map((response:any) => response.replace(/<\/?[^>]+(>|$)/g, ''));
            console.log("toSpeak",toSpeak);
           }
           if(this.langSelected == 'hi'){
            // toSpeak = responseData.body?.fullFillmentText;
            toSpeak = responseData.body?.voice_msg?.map((response:any) => response.replace(/<\/?[^>]+(>|$)/g, ''));
            console.log("toSpeakfullfillment",toSpeak);
           }

        var msg = new SpeechSynthesisUtterance(toSpeak);
        msg.lang = this.langSelected;
        msg.volume = 1; // From 0 to 1
        msg.rate = 1; // From 0.1 to 10
        // msg.pitch = 2; // From 0 to 2
        if(msg.onend){
          clearTimeout(this.myTimeout);
        }
        window.speechSynthesis.speak(msg);
       // this.voiceAssistState = true;
       } else{
         // Speech Synthesis Not Supported 😣
         alert("Sorry, your browser doesn't support text to speech!");
       }
      }
    //  console.log(responseData.body?.message.content);
      this.messages.push(
        new Message('bot',responseData.body?.message.content,responseData.body?.message.richcontent,'assets/bot.jpg',false)
     );
     console.log("this.mesages aftr push in mesge-item",this.messages);
   });
    }
  }

}
